<script lang="ts">
    import { createEventDispatcher } from 'svelte'

    /**
     * Is this the principal call to action on the page?
     */
    export let primary = true

    /**
     * How large should the button be?
     */
    export let size = 'medium'
    /**
     * Button contents
     */
    export let label = ''

    export let style = 'contained' //outlined

    export let label_position = 'default'

    export let disabled = false

    export let isSubmit = false
    let mode = primary ? 'primary' : 'secondary'

    const dispatch = createEventDispatcher()

    /**
     * Optional click handler
     */
    function onClick(event: any) {
        dispatch('click', event)
    }
</script>

<section>
    <button type={isSubmit ? 'submit' : 'button'} class={['button', `button--${size}`, mode, style].join(' ')} on:click={onClick} {disabled}>
        <span class={[label_position].join(' ')}>{label}</span>
        <slot />
    </button>
</section>

<style lang="scss">
    section {
        background: var(--component-background, transparent);
        max-width: 100%;

        button {
            border: 0.2rem solid var(--color-white);
            border-radius: 0.5rem;
            width: 100%;

            &.contained {
                --background-color: var(--color-accent);
                --color: var(--color-white);
                --border: 0.2rem solid var(--color-accent);

                &.secondary {
                    background-color: var(--color-accent);
                }

                background-color: var(--background-color);
                color: var(--color);

                :global(a) {
                    color: var(--color-white);
                    display: contents;
                }

                :global(i) {
                    color: var(--color-white) !important;
                }
            }

            &.outlined {
                background-color: var(--color-white);
                color: var(--color-accent);
                border: 0.2rem solid var(--color-accent);

                &.secondary {
                    border: 0.2rem solid var(--color-accent);
                    background-color: var(--color-white);
                    // color: var(--color-accent);
                }
            }

            span {
                font-weight: var(--font-weight);

                &.left {
                    float: left;
                }

                &.right {
                    float: right;
                }
            }
        }

        .button--small {
            font-size: var(--font-size--small);
            padding: 0.5rem 1rem;
        }
    }
</style>
